import { increment } from '@/libs/firebase';
import { setDoc, generateId, getCollectionRef, getDocRef, getDoc } from '../libs/firestore';

function incrementUpvote(featureName: string, count: number) {
    return setDoc(`upvotes/${featureName}`, {
        upvotes: increment(count),
        name: featureName
    }, true);
}

function getUpvotes(featureName: string) {
    return getDoc(`upvotes/${featureName}`);
}

export { incrementUpvote, getUpvotes }
